import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useLocation } from 'react-router-dom';
import { fetchEntriesDateRange } from '../queries/queries';
import EntryList from '../components/entry-list/entry-list';

import './list-entries.css';

const ListEntries = () => {
  const location = useLocation();

  const { startDate, endDate } = useMemo(() => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const startOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    const formatDate = (date) => date.toISOString().slice(0, 10);

    switch (location.pathname) {
      case '/list-entries/today':
        return { startDate: formatDate(new Date()), endDate: formatDate(new Date()) };
      case '/list-entries/this-week':
        return { startDate: formatDate(startOfWeek), endDate: formatDate(new Date()) };
      case '/list-entries/this-month':
        return { startDate: formatDate(startOfMonth), endDate: formatDate(new Date()) };
      default:
        return { startDate: '1970-01-01', endDate: formatDate(new Date()) }; // Fetch all by default
    }
  }, [location.pathname]);

  // Use react-query to fetch entries
  const {
    data: entries,
    isLoading: isEntriesLoading,
    error: entriesError,
  } = useQuery(
    ['entries', startDate, endDate],
    fetchEntriesDateRange
  );

  if (entriesError) {
    return <p>Error fetching entries: {entriesError.message}</p>;
  }

  return (
    <div className="list-entries">
      <Routes>
        <Route path="/" element={<h3>Select a range to view entries</h3>} />
        <Route path="today" element={<h3>Entries for Today</h3>} />
        <Route path="this-week" element={<h3>Entries for This Week</h3>} />
        <Route path="this-month" element={<h3>Entries for This Month</h3>} />
      </Routes>

      <h2>Training Entries</h2>
      <EntryList entries={entries} isLoading={isEntriesLoading} />
    </div>
  );
};

export default ListEntries;
