import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AddEntry from './routes/add-entry';
import ListEntries from './routes/list-entries';
import ManageTypes from './routes/manage-types';
import ListSummary from './routes/list-summary';

function App() {
  return (
    <Router>
      <div className="App">
        <h1>Training Log</h1>
        <nav>
          <ul>
            <li><Link to="/add-entry">Add Entry</Link></li>
            <li><Link to="/manage-types">Manage Types</Link></li>
            <li><Link to="/list-summary">List Summary</Link></li>
            <ul>
                <li><Link to="/list-summary/today">Today</Link></li>
                <li><Link to="/list-summary/this-week">This Week</Link></li>
                <li><Link to="/list-summary/this-month">This Month</Link></li>
              </ul>            
            <li><Link to="/list-entries">List Entries</Link>
              <ul>
                <li><Link to="/list-entries/today">Today</Link></li>
                <li><Link to="/list-entries/this-week">This Week</Link></li>
                <li><Link to="/list-entries/this-month">This Month</Link></li>
              </ul>
            </li>
          </ul>
        </nav>

        <Routes>
          <Route path="/add-entry" element={<AddEntry />} />
          <Route path="/list-summary/*" element={<ListSummary />} />
          <Route path="/list-entries/*" element={<ListEntries />} />
          <Route path="/manage-types" element={<ManageTypes />} />
          <Route path="/" element={<h2>Welcome to the Training Log</h2>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
