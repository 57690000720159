import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import './manage-types.css';

const ManageTypes = () => {
  const [categories, setCategories] = useState([]);
  const [trainingTypes, setTrainingTypes] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [newCategory, setNewCategory] = useState('');
  const [newTrainingType, setNewTrainingType] = useState('');

  const apiHost = process.env.REACT_APP_API_HOST;

  const fetchCategories = useCallback(() => {
    axios.get(`${apiHost}/categories`)
      .then(response => {
        const sortedCategories = response.data.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
        setCategories(sortedCategories);
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, [apiHost]);

  const fetchTrainingTypesForCategory = useCallback((categoryId) => {
    axios.get(`${apiHost}/types?categoryId=${categoryId}`)
      .then(response => {
        const sortedTypes = response.data.sort((a, b) => a.trainingType.localeCompare(b.trainingType));
        setTrainingTypes(sortedTypes)
      })
      .catch(error => console.error('Error fetching training types:', error));
  }, [apiHost]);

  // Fetch categories and training types on mount
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  // Add new category
  const handleAddCategory = () => {
    if (newCategory) {
      axios.post(`${apiHost}/categories`, { categoryName: newCategory })
        .then(response => {
          alert('Category added!');
          setNewCategory('');
          fetchCategories(); // Refresh categories
        })
        .catch(error => console.error('Error adding category:', error));
    }
  };

  // Add new training type
  const handleAddTrainingType = () => {
    if (newTrainingType && selectedCategoryId) {
      axios.post(`${apiHost}/types`, { trainingType: newTrainingType, trainingCategoryId: selectedCategoryId })
        .then(response => {
          alert('Training Type added!');
          setNewTrainingType('');
          // setSelectedCategoryId('');
          fetchTrainingTypesForCategory(selectedCategoryId); // Refresh training types
        })
        .catch(error => console.error('Error adding training type:', error));
    }
  };

  // Handle category selection
  const handleCategorySelect = (categoryId) => {
    setSelectedCategoryId(categoryId);
    fetchTrainingTypesForCategory(categoryId); // Fetch types for the selected category
  };


  // Delete a category and its associated types
  const handleDeleteCategory = (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category and all its associated training types?')) {
      axios.delete(`${apiHost}/categories/${categoryId}`)
        .then(() => {
          alert('Category and its types deleted!');
          setSelectedCategoryId('');
          fetchCategories();
          setTrainingTypes([]); // Clear types after category is deleted
        })
        .catch(error => console.error('Error deleting category:', error));
    }
  };

  // Delete a training type
  const handleDeleteTrainingType = (typeId) => {
    if (window.confirm('Are you sure you want to delete this training type?')) {
      axios.delete(`${apiHost}/types/${typeId}`)
        .then(() => {
          alert('Training type deleted!');
          fetchTrainingTypesForCategory(selectedCategoryId); // Refresh types after deleting
        })
        .catch(error => console.error('Error deleting training type:', error));
    }
  };

  return (
    <div>
      <h2>Manage Training Categories & Types</h2>

      {/* Category Management */}
      <div>
        <h3>Categories</h3>
        <ul>
          {categories.map(category => (
            <li key={category.id}>
              <button className={category.id === selectedCategoryId ? 'category-btn category-btn--selected' : 'category-btn'} onClick={() => handleCategorySelect(category.id)}>
                {category.categoryName}
              </button>
              <button onClick={() => handleDeleteCategory(category.id)}>Delete Category</button>
            </li>
          ))}
        </ul>
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="New Category"
        />
        <button onClick={handleAddCategory}>Add Category</button>
      </div>

      {/* Training Type Management */}
      <div>
        <h3>Training Types for Selected Category</h3>
        <ul>
          {trainingTypes.length === 0 && <p>No training types for this category.</p>}
          {trainingTypes.map(type => (
            <li key={type.id}>
              {type.trainingType}
              <button onClick={() => handleDeleteTrainingType(type.id)}>Delete Type</button>
            </li>
          ))}
        </ul>

        {/* Add new training type */}
        <div>
          <input
            type="text"
            value={newTrainingType}
            onChange={(e) => setNewTrainingType(e.target.value)}
            placeholder="New Training Type"
          />
          <button onClick={handleAddTrainingType}>Add Training Type</button>
        </div>
      </div>
    </div>
  );
}

export default ManageTypes;