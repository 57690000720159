import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './add-entry.css';

const AddEntry = () => {
  const [date, setDate] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(''); // Store selected category
  const [trainingTypeId, setTrainingTypeId] = useState('');
  const [repetitions, setRepetitions] = useState('');
  const [categories, setCategories] = useState([]); // Store categories
  const [trainingTypes, setTrainingTypes] = useState([]); // Store training types for selected category
  const [todaysEntries, setTodaysEntries] = useState([]);

  const apiHost = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    // Fetch available categories from the API
    axios.get(`${apiHost}/categories`)
      .then(response => {
        const sortedCategories = response.data.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
        setCategories(sortedCategories);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });

    // Fetch today's entries from the API
    const today = new Date().toISOString().slice(0, 10); // Using ISO to ensure consistency
    axios.get(`${apiHost}/entries/date-range`, {
      params: {
        startDate: today,
        endDate: today
      }
    })
      .then(response => {
        const sortedEntries = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setTodaysEntries(sortedEntries);
      })
      .catch(error => {
        console.error('Error fetching today\'s entries:', error);
      });

    // Fetch the latest entry from the API
    axios.get(`${apiHost}/entries/latest`)
      .then(response => {
        const latestEntry = response.data;
        if (latestEntry) {
          setTrainingTypeId(latestEntry.trainingTypeId);
        }
        setDate(new Date().toISOString().slice(0, 16)); // Use ISO for the date input
      })
      .catch(error => {
        console.error('Error fetching latest entry:', error);
        setDate(new Date().toISOString().slice(0, 16));
      });
  }, [apiHost]);

  // Fetch training types when a category is selected
  const handleCategoryChange = (categoryId) => {
    setSelectedCategoryId(categoryId);
    if (categoryId) {
      axios.get(`${apiHost}/types`, {
        params: {
          categoryId
        }
      })
        .then(response => {
          const sortedTypes = response.data.sort((a, b) => a.trainingType.localeCompare(b.trainingType));
          setTrainingTypes(sortedTypes);
          setTrainingTypeId(''); // Reset training type when category changes
        })
        .catch(error => {
          console.error('Error fetching training types:', error);
        });
    } else {
      setTrainingTypes([]); // Clear training types if no category is selected
    }
  };

  const handleSubmit = (e, value) => {
    e?.preventDefault();

    const newEntry = {
      date,
      trainingTypeId: parseInt(trainingTypeId),
      repetitions: parseInt(value || repetitions)
    };

    axios.post(`${apiHost}/entries`, newEntry)
      .then(response => {
        console.debug('Entry added successfully!');
        setDate(new Date().toISOString().slice(0, 16)); // Reset to current date
        // setTrainingTypeId('');
        setRepetitions('');

        // Fetch today's entries again
        const today = new Date().toISOString().slice(0, 10);
        axios.get(`${apiHost}/entries/date-range`, {
          params: {
            startDate: today,
            endDate: today
          }
        })
          .then(response => {
            const sortedEntries = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
            setTodaysEntries(sortedEntries);
          })
          .catch(error => {
            console.error('Error fetching today\'s entries:', error);
          });
      })
      .catch(error => {
        console.error('Error adding entry:', error);
        alert('Failed to add entry.');
      });
  };

  // Quick repetitions button click handler
  const handleQuickRepetitionClick = (value) => {
    setRepetitions(value);
    handleSubmit(null, value); // Automatically submit after setting repetitions
  };

  console.log('add-entry render', { date, selectedCategoryId, trainingTypeId, repetitions, categories, trainingTypes, todaysEntries });

  return (
    <div className="add-entry">
      <h2>Add Training Entry</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Date:</label>
          <input
            type="datetime-local"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <div>
            <label>Category:</label>
            <div className="button-group">
              {categories.map(category => (
                <div key={category.id} className="button-wrapper">
                  <input
                    type="radio"
                    id={`category-${category.id}`}
                    name="category"
                    value={category.id}
                    checked={selectedCategoryId === category.id}
                    onChange={() => handleCategoryChange(category.id)}
                  />
                  <label htmlFor={`category-${category.id}`}>{category.categoryName}</label>
                </div>
              ))}
            </div>
          </div>

          {selectedCategoryId && (
            <div>
              <label>Training Type:</label>
              <div className="button-group">
                {trainingTypes.map(type => (
                  <div key={type.id} className="button-wrapper">
                    <input
                      type="radio"
                      id={`type-${type.id}`}
                      name="trainingType"
                      value={type.id}
                      checked={trainingTypeId === type.id}
                      onChange={() => setTrainingTypeId(type.id)}
                    />
                    <label htmlFor={`type-${type.id}`}>{type.trainingType}</label>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div>
            <label>Repetitions:</label>
            {selectedCategoryId && trainingTypeId && (
              <>
                <div className="quick-reps">
                  {/* Quick selection buttons */}
                  {[10, 15, 20, 30, 45, 60].map(value => (
                    <button
                      type="button"
                      key={value}
                      onClick={() => handleQuickRepetitionClick(value)}
                      className="quick-reps-btn"
                    >
                      {value}
                    </button>
                  ))}
                </div>
                <input
                  type="number"
                  value={repetitions}
                  onChange={(e) => setRepetitions(e.target.value)}
                  required
                />
              </>
            )}
          </div>
        </div>
        <button type="submit">Add Entry</button>
      </form>

      <h2>Today's Entries</h2>
      <ul>
        {todaysEntries.length === 0 && <p>No entries for today.</p>}
        {todaysEntries.map(entry => (
          <li key={entry.trainingType}>
            Date: {new Date(entry.date).toLocaleString()},
            Training Type: {entry.trainingType},
            Repetitions: {entry.totalRepetitions}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddEntry;
