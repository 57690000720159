// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-entry {
  background-color: #f0f0f0;
  padding: 20px;
}

.form-group {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  max-width: 1024px;
}


.button-wrapper {
  /* Hide radio input */
  input[type="radio"] {
    display: none;
  }

  label {
    display: inline-block;
    padding: 10px 15px;
    margin: 5px;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;

    &:hover {
      background-color: #e0e0e0;
      border-color: #999;
    }
  }

  input[type="radio"]:checked + label {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
  }
}

.quick-reps {
  margin-block: 6px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.quick-reps-btn {
  padding: 10px 15px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/add-entry.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,kCAAkC;EAClC,8BAA8B;EAC9B,iBAAiB;AACnB;;;AAGA;EACE,qBAAqB;EACrB;IACE,aAAa;EACf;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,oDAAoD;;IAEpD;MACE,yBAAyB;MACzB,kBAAkB;IACpB;EACF;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;EACd;AACF;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,oDAAoD;;EAEpD;IACE,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;EACd;AACF","sourcesContent":[".add-entry {\n  background-color: #f0f0f0;\n  padding: 20px;\n}\n\n.form-group {\n  margin-top: 24px;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  justify-content: space-between;\n  max-width: 1024px;\n}\n\n\n.button-wrapper {\n  /* Hide radio input */\n  input[type=\"radio\"] {\n    display: none;\n  }\n\n  label {\n    display: inline-block;\n    padding: 10px 15px;\n    margin: 5px;\n    background-color: #f0f0f0;\n    border: 2px solid #ccc;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s, border-color 0.3s;\n\n    &:hover {\n      background-color: #e0e0e0;\n      border-color: #999;\n    }\n  }\n\n  input[type=\"radio\"]:checked + label {\n    background-color: #007bff;\n    border-color: #007bff;\n    color: white;\n  }\n}\n\n.quick-reps {\n  margin-block: 6px;\n  display: flex;\n  gap: 12px;\n  flex-wrap: wrap;\n}\n\n.quick-reps-btn {\n  padding: 10px 15px;\n  border: 2px solid #ccc;\n  border-radius: 5px;\n  background-color: #f0f0f0;\n  cursor: pointer;\n  transition: background-color 0.3s, border-color 0.3s;\n\n  &:hover {\n    background-color: #007bff;\n    border-color: #007bff;\n    color: white;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
