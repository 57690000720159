import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Route, Routes, useLocation } from 'react-router-dom';

const ListEntries = () => {
  const [entries, setEntries] = useState([]);
  const apiHost = process.env.REACT_APP_API_HOST;
  const location = useLocation();

  useEffect(() => {
    const fetchEntries = (startDate, endDate) => {
      axios.get(`${apiHost}/entries/date-range`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then(response => {
        setEntries(response.data);
      })
      .catch(error => {
        console.error('Error fetching entries:', error);
      });
    };

    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const formatDate = (date) => date.toLocaleString().slice(0, 10);

    switch (location.pathname) {
      case '/list-entries/today':
        fetchEntries(formatDate(new Date()), formatDate(new Date()));
        break;
      case '/list-entries/this-week':
        fetchEntries(formatDate(startOfWeek), formatDate(new Date()));
        break;
      case '/list-entries/this-month':
        fetchEntries(formatDate(startOfMonth), formatDate(new Date()));
        break;
      default:
        fetchEntries('1970-01-01', formatDate(new Date()));  // Fetch all if no subroute matches
        break;
    }
  }, [apiHost, location.pathname]);

  return (
    <div>
      <h2>Training Entries</h2>
      <Routes>
        <Route path="/" element={<h3>Select a range to view entries</h3>} />
        <Route path="today" element={<h3>Entries for Today</h3>} />
        <Route path="this-week" element={<h3>Entries for This Week</h3>} />
        <Route path="this-month" element={<h3>Entries for This Month</h3>} />
      </Routes>

      <ul>
        {entries.length === 0 && <p>No entries found for this period.</p>}
        {entries.map(entry => (
          <li key={entry.date + '_' + entry.trainingType}>
            Date: {new Date(entry.date).toLocaleString()}, 
            Training Type: {entry.trainingType}, 
            Repetitions: {entry.totalRepetitions}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListEntries;
