import React, { useMemo } from 'react';
import {
  Route,
  Routes,
  useLocation
} from 'react-router-dom';
import { fetchEntriesDateRangeSummary } from '../queries/queries';
import { useQuery } from 'react-query';

import './list-summary.css';

const ListSummary = () => {

  const location = useLocation();

  const { startDate, endDate } = useMemo(() => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const startOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    const formatDate = (date) => date.toISOString().slice(0, 10);

    switch (location.pathname) {
      case '/list-summary/today':
        return { startDate: formatDate(new Date()), endDate: formatDate(new Date()) };
      case '/list-summary/this-week':
        return { startDate: formatDate(startOfWeek), endDate: formatDate(new Date()) };
      case '/list-summary/this-month':
        return { startDate: formatDate(startOfMonth), endDate: formatDate(new Date()) };
      default:
        return { startDate: '1970-01-01', endDate: formatDate(new Date()) }; // Fetch all by default
    }
  }, [location.pathname]);

  // Use react-query to fetch entries
  const {
    data: summary,
    isLoading: isSummaryLoading,
    error: summaryError,
  } = useQuery(
    ['summary', startDate, endDate],
    fetchEntriesDateRangeSummary
  );

  if (summaryError) {
    return <p>Error fetching summary: {summaryError.message}</p>;
  }

  return (
    <div className="list-summary">
      <Routes>
        <Route path="/" element={<h3>Select a range to view summary</h3>} />
        <Route path="today" element={<h3>Summary for Today</h3>} />
        <Route path="this-week" element={<h3>Summary for This Week</h3>} />
        <Route path="this-month" element={<h3>Summary for This Month</h3>} />
      </Routes>

      <h1>List Summary</h1>
      {isSummaryLoading && <p>Loading summary...</p>}
      {!isSummaryLoading && summary && (
        <ul>
          {
            summary.totalSummaries.map(({
              trainingTypeId,
              trainingType,
              trainingCategoryId,
              totalRepetitions
            }) => (
              <li key={trainingCategoryId + '_' + trainingTypeId}>
                <h2>{trainingType} ({totalRepetitions})</h2>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

export default ListSummary;
