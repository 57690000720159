// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-btn {
  padding: 5px;
  margin-right: 12px;
  margin-bottom: 6px;
  background-color: white;
  border-radius: 6px;
}

.category-btn.category-btn--selected {
  background-color: #466c9a;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/manage-types.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":[".category-btn {\n  padding: 5px;\n  margin-right: 12px;\n  margin-bottom: 6px;\n  background-color: white;\n  border-radius: 6px;\n}\n\n.category-btn.category-btn--selected {\n  background-color: #466c9a;\n  color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
